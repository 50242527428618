@import 'assets/sass/variables';
$ingredientFormWidth: 400px;

.ingredientList {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ingredientsLabel {
  border: $border-disabled;
  padding: $grid-small;
  margin: 0;
  @media screen and (max-width: $bp-md) {
    margin: 0;
  }
  li {
    font-size: 14px;
    display: inline-block;
    &:after { 
      content: ", "; 
      white-space: pre;
    } 
    &:last-child {
      &:after { 
        content: ""; 
      } 
    }
  }
}
.ingredientsDialog {
  width: $ingredientFormWidth;
  margin-left: auto;
  @media screen and (max-width: $bp-md) {
    width: 100%;
  }
}
.nutritionHeadline {
  color: $white-color;
  background-color: $color-secondary;
  padding: $grid-small;
  border: $border-disabled;
  margin: $grid-base 0 0 0;
}
.warningMessageContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.warningHeadline {
  margin: 0;
}
