@import 'assets/sass/variables';

.itemList {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.warningMessageContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.warningHeadline {
  margin: 0;
}