@import 'assets/sass/variables';

.contentContainer {
  flex-grow: 1;
  margin-top: $headerHeight;
  padding: $grid-giant;
  @media screen and (max-width: $bp-sm) {
    padding: $grid-base;
  }
  @media screen and (min-width: $bp-sm) {
    margin-left: $sidebarWidth;
  }
}