@import 'assets/sass/variables';

.appContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  background-color: $white-color;
}
.appContent {
  display: flex;
  min-height: 100vh;
  background-color: $background-color;
}