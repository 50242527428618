@import 'assets/sass/variables';
$ingredientFormWidth: 500px;

.ingredientForm {
  width: $ingredientFormWidth;
  margin: 0;
  @media screen and (max-width: $bp-md) {
    width: auto;
  }
}
.formCheckbox {
  width: 100%;
}
.saveButton {
  display: block;
  margin-left: auto;
}
.divider {
  margin: $grid-base 0 $grid-base 0;
}