@import 'assets/sass/variables';

.cardList {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.cardListContainer {
  display: flex;
  flex-wrap: wrap;
}
.cardListItemContainer2 {
  width: 50% !important;
}
.cardListItemContainer4 {
  width: 25% !important;
}
.cardListItemContainer5 {
  width: 20% !important;
}
.cardListItemContainer {
  width: 50%;
  display: flex;
  padding: 0;
  margin: 0;
  @media screen and (max-width: $bp-md) {
    width: 50% !important;
  }
  @media screen and (max-width: $bp-xs) {
    width: 100% !important;
  }
}
.cardListClickable {
  cursor: pointer;
  &:hover {
    border: solid 1px $color-primary;
  }
}
.cardListItemContent {
  display: flex;
  width: 100%;
  align-items: center;
  padding: $grid-base;
  margin-bottom: $grid-base;
  border: $border-disabled;
  background-color: $white-color;
  margin: $grid-small;
  @media screen and (max-width: $bp-md) {
    flex-direction: column;
  }
}
.cardListItemContentVertical {
  flex-direction: column;
  text-align: center;
}
.cardListItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  @media screen and (max-width: $bp-md) {
    text-align: center;
  }
}
.cardListImage {
  margin-right: $grid-base;
  img {
    max-width: 125px;
  }
}
.cardListImageVertical {
  img {
    max-width: 125px;
  }
}
.cardListItemDetails {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.buttonContainer {
  margin: auto 0 0 0;
  text-align: right;
  padding: 0 $grid-small $grid-small $grid-small;
  button { 
    margin-right: $grid-small;
    &:last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: $bp-md) {
    text-align: center;
    button {
      width: 100%;
      margin-bottom: $grid-tiny;
    }
  }
}
.buttonContainerVertical {
  text-align: center;
  padding: 0 $grid-small $grid-small $grid-small;
  button {
    width: 100%;
    margin-bottom: $grid-tiny;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.itemHeadline {
  margin-bottom: $grid-small;
  @media screen and (max-width: $bp-md) {
    text-align: center;
  }
}
.itemDescription {
  margin-bottom: 0;
  @media screen and (max-width: $bp-md) {
    text-align: center;
  }
}
.itemRender {
  margin-top: auto;
}
.warningContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.warningHeadline {
  margin-bottom: $grid-small;
}
.paginationContainer {
  display: flex;
  justify-content: center;
  margin: auto 0 0 0;
  padding-top: $grid-base;
}
.divider {
  width: 100%;
  margin: $grid-base 0 $grid-base 0;
}
.listItemDetailsVertical {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.listItemDetails {
  display: flex;
  flex-direction: column;
  padding: $grid-small $grid-small 0 $grid-small;
}