@import 'assets/sass/variables';

.userMenu {
  margin-right: $grid-base;
}
.avatarContainer {
  background-color: black;
  margin-right: $grid-tiny;
}
.avatarIcon {
  width: 25px;
  height: 25px;
  background-color: $color-corporate;
}
.avatarName {
  color: $white-color;
  @media screen and (max-width: $bp-sm) {
    display: none;
  }
}
.headerMenu {
  min-width: 140px;
}