@import 'assets/sass/variables';

.tabPanelContainer {
  display:flex;
  height: 100%;
  flex-direction: column;
}
.tabContainer {
  border-bottom: $border-disabled;
  margin-bottom: $grid-base;
}
.tab {
  font-family: 'Arimo';
  font-size: 15px;
  font-weight: 500;
}
.tabPanelContent {
  height: 100%;
}