$grid-tiny: 5px !default;
$grid-small: 10px !default;
$grid-base: 15px !default;
$grid-large: 30px !default;
$grid-giant: 50px !default;

$bp-xs: 480px;
$bp-sm: 767px;
$bp-md: 1200px;
$bp-lg: 1920px;

$none: 0 !default;
$border-thin: 1px !default;
$border-thick: 2px !default;

$white-color: #ffffff;
$offset-color: #eeeeee;
$background-color: #fafafa;
$black-color: #282828 !default;
$medium-gray: #f7f8fc !default;
$smoke-bg: #f5f5f5 !default;
$transparent-bg: transparent !default;
$dark-gray: #9a9a9a !default;
$light-gray: #e5e5e5 !default;
$light-color: #eaeaea !default;

$font-size-h1: 42px !default;
$font-size-h2: 34px !default;
$font-size-h3: 26px !default;
$font-size-h4: 22px !default;
$font-size-h5: 16px !default;
$font-size-h6: 12px !default;

$color-primary: #465de4 !default;
$color-secondary: #282828 !default;
$color-info: #0288d1;
$color-corporate: #d62b70;
$color-alert: red;

$color-gray-primary: #adaeb0 !default;
$color-gray-normal: #dadada !default;
$color-orange: #ed6a5a;
$color-grey-blue: #8c96ab;
$color-disabled: rgba(0, 0, 0, 0.15);
$color-body: #505050;

$border-primary: solid $border-thick $color-primary;
$border-grey-primary: solid $border-thin $dark-gray;
$border-black: solid $border-thin $black-color;
$border-btn: solid $border-thick $color-secondary;
$border-grey: solid $border-thin $color-gray-primary;
$border-disabled: solid $border-thin $color-disabled;

$box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.08);

$footerHeight: 80px; 
$footerLogoWidth: 100px;

$sidebarWidth: 60px;
$sidebarWidthOpen: 200px;
$sidebarIconSize: 30px;

$headerHeight: 80px;
$headerBackgroundColor: #111;
$headerLogoWidth: 175px;

$minButtonWidth: 85px;
$tabbedWindowHeight: calc(100vh - 240px);

$container-width: 1196px;
