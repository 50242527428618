@import 'assets/sass/variables';

.sidebarContainer {
  min-height: 400px;
  position: fixed;
  z-index: 888;
  top: $headerHeight;
  width: $sidebarWidth;
  background-color: $color-primary;
  color: $white-color;
  overflow-x: hidden;
  min-height: calc(100vh - #{$headerHeight});
  -webkit-transition: width .5s;
  transition: width .5s;
  @media screen and (min-width: $bp-md) {
    &:hover {
      width: $sidebarWidthOpen;
    }
  }
}
.sidebarList {
  margin-top: $grid-base;
}
.sidebarOpen {
  width: $sidebarWidth;
  overflow-x: unset;

  @media screen and (max-width: $bp-sm) {
    width: 100%;
  }
}
.sidebarClosed {
  width: 0;
}
.sidebarHidden {
  width: 0;
  display: none;
}