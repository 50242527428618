@import 'assets/sass/variables';

body {
  margin: 0;
  color: #282828;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 { font-size: $font-size-h1; }
h2 { font-size: $font-size-h2; }
h3 { font-size: $font-size-h3; }
h4 { font-size: $font-size-h4; }
h5 { font-size: $font-size-h5; }
h6 { font-size: $font-size-h6; }
h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  margin: $grid-base 0 $grid-tiny 0;
}
p {
  font-size: 18px;
  line-height: 28px;
}
.MuiDialog-paper {
  max-width: unset !important;
}
.MuiButton-contained, .MuiButton-outlined, .MuiButton-text {
  box-shadow: none !important;
}
.MuiDialogTitle-root {
  padding: $grid-base;
}
.MuiDialog-paper {
  max-height: unset;
}
.MuiTabs-flexContainer {
  @media screen and (max-width: $bp-sm) {
    flex-wrap: wrap;
  }
}